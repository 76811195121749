import { cx } from "cva";
import { Fragment, ReactNode } from "react";
import { constants } from "~/common/constants";
import { FormattedDate } from "~/components/FormattedDate";
import { ChartPackLabel } from "~/components/ChartPackLabel";
import { Body, Headline, Link } from "~/ui-library";

export type ContentItemProps = {
  className?: string;
  kicker?: string | null;
  body?: ReactNode;
  footer?: string | null;
  headline?: string;
  eyebrow?: string | null;
  id?: string;
  isChartPack?: boolean;
  kickerHref?: string | null;
  headlineHref?: string;
  onKickerClick?: (kicker: string | null | undefined) => void;
  onHeadlineClick?: (headline: string) => void;
  asHero?: boolean;
  date?: string | null;
  displayDate?: boolean;
  displayKicker?: boolean;
  displayFooter?: boolean;
  /** When top, place it above the title. When bottom, place it beneath the date and footer */
  kickerPosition?: "top" | "bottom";
  /**
   * When true, in mobile, the headline will be level 5. When desktop, it will be level 2.
   * When false, the headline will always be level 2
   *
   * Defaults to true */
  hasResponsiveHeadline?: boolean;
};

type ContentItemTitleProps = {
  asHero: boolean;
  hasResponsiveHeadline: boolean;
  headline?: string;
  headlineHref?: string;
  onClick?: (headline: string) => void;
};

const ContentItemHeroHeadline = ({
  hasResponsiveHeadline,
  headline,
}: Pick<ContentItemTitleProps, "hasResponsiveHeadline" | "headline">) =>
  hasResponsiveHeadline ? (
    <>
      <Headline className="hidden lg:block" as="h3" level={2}>
        {headline}
      </Headline>
      <Headline className="block lg:hidden" as="h3" level={5}>
        {headline}
      </Headline>
    </>
  ) : (
    <Headline as="h3" level={2}>
      {headline}
    </Headline>
  );

const ContentItemTitle = ({
  asHero,
  hasResponsiveHeadline,
  headline,
  headlineHref,
  onClick,
}: ContentItemTitleProps) => {
  if (!headline) {
    return null;
  }

  const headlineComponent = asHero ? (
    <ContentItemHeroHeadline
      hasResponsiveHeadline={hasResponsiveHeadline}
      headline={headline}
    />
  ) : (
    <Headline as="h3" level={5}>
      {headline}
    </Headline>
  );

  if (headlineHref) {
    return (
      <Link
        appearance="plain"
        className="text-gray-900"
        href={headlineHref}
        data-event-tree-target={
          constants.EventTreeTarget.CONTENT_ITEM_HEADLINE_LINK
        }
        data-analytics-headline={headline}
        onClick={() => onClick?.(headline)}
      >
        {headlineComponent}
      </Link>
    );
  }
  return headlineComponent;
};

const ContentItemFooter = ({
  date,
  displayDate,
  displayFooter,
  footer,
  isChartPack,
}: {
  date?: string | null;
  displayDate?: boolean;
  displayFooter?: boolean;
  footer?: string;
  isChartPack?: boolean;
}) => {
  const showDate = Boolean(displayDate && date);
  const showFooter = Boolean(displayFooter && footer);

  if (!showDate && !showFooter) {
    return null;
  }

  const dateRow = [
    showDate ? (
      <FormattedDate date={new Date(date!)} className="inline" />
    ) : null,
    showFooter ? footer : null,
    isChartPack ? (
      <ChartPackLabel
        className="inline-block"
        bodySize="extraSmall"
        iconSize="small"
      />
    ) : null,
  ].filter(Boolean);

  return (
    <Body className="text-gray-800" size="extraSmall">
      {dateRow.map((item, idx) => (
        <Fragment key={idx}>
          {idx > 0 ? (
            <span className="mx-xs inline-block text-gray-300">&#x2022;</span>
          ) : null}
          {item}
        </Fragment>
      ))}
    </Body>
  );
};

export const ContentItem: React.FC<ContentItemProps> = ({
  kicker,
  headline,
  body,
  footer,
  eyebrow,
  kickerHref,
  headlineHref,
  className,
  date,
  isChartPack,
  onKickerClick,
  onHeadlineClick,
  asHero = false,
  displayDate = true,
  displayFooter = true,
  displayKicker = true,
  hasResponsiveHeadline = true,
}) => {
  const showKicker = Boolean(displayKicker && kicker);
  const renderedKicker = (
    <Body
      size="small"
      className={"uppercase tracking-headline6-block text-primary"}
    >
      {kickerHref ? (
        <Link
          href={kickerHref}
          data-event-tree-target={
            constants.EventTreeTarget.CONTENT_ITEM_KICKER_LINK
          }
          onClick={() => onKickerClick?.(kicker)}
        >
          {kicker}
        </Link>
      ) : (
        <span>{kicker}</span>
      )}
    </Body>
  );
  return (
    <div className={cx("flex flex-col gap-xs", className)}>
      {showKicker ? renderedKicker : null}

      {eyebrow ? (
        <Body size="extraSmall" className="text-gray-800">
          {eyebrow}
        </Body>
      ) : null}

      <ContentItemTitle
        asHero={asHero}
        hasResponsiveHeadline={hasResponsiveHeadline}
        headline={headline}
        headlineHref={headlineHref}
        onClick={onHeadlineClick}
      />

      {body ? (
        <Body size="small" className="py-xs">
          {body}
        </Body>
      ) : null}

      <ContentItemFooter
        footer={footer ?? undefined}
        displayDate={displayDate}
        displayFooter={displayFooter}
        isChartPack={isChartPack}
        date={date}
      />
    </div>
  );
};
